import { createContext, useContext, useState, useMemo } from "react";

const SearchPatientDataContext = createContext();

export const SearchPatientDataProvider = ({ children }) => {
  const [searchFormData, setSearchFormData] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showRegForm, setShowRegForm] = useState(false);
  const [hideFamily, setHideFamily] = useState(true);
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const value = useMemo(
    () => ({
      patientList,
      setPatientList,
      showTable,
      setShowTable,
      showRegForm,
      setShowRegForm,
      searchFormData,
      setSearchFormData,
      hideFamily,
      setHideFamily,
      debouncedSearch, setDebouncedSearch
    }),
    [patientList, showTable, showRegForm, searchFormData,debouncedSearch]
  );

  return (
    <SearchPatientDataContext.Provider value={value}>
      {children}
    </SearchPatientDataContext.Provider>
  );
};

export const useSearchPatientData = () => useContext(SearchPatientDataContext);
