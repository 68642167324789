import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import apiService from "../../api/apiService";
const SubAdminContext = createContext();

export const SubAdminProvider = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [SubAdmin, setSubAdmin] = useState({
    id: "",
    name: "",
    mobilenumber: "",
    email: "",
    role: "",
    flcty_id: "",
    type: "",
    clinicName: "",
    clinicType: "",
    clinicPhone: "",
    clinicEmail: "",
    clinicImg: "",
    clinicAddress: "",
  });

  useEffect(() => {
    const token = sessionStorage.getItem("subadminToken");
    if (token) {
      getUserInfo();
    }
  }, []);

  const getUserInfo = async () => {
    try {
      const response = await apiService.getuserInfo();
      if (response.status === 200) {
        const userData = response.data.userData;
        const facilityDetails = userData?.facility_details || {};
        // Map data with proper checks
        setSubAdmin({
          id: userData.id || "",
          name: userData.username || "",
          mobilenumber: userData.mobilenumber || "",
          email: userData.email || "",
          role: "", // You can map the role if available
          flcty_id: userData?.fclty_id || "",
          password:"",
          type: "",
          clinicName: facilityDetails?.fclty_nm || "",
          clinicType: facilityDetails?.fclty_type || "",
          clinicPhone: facilityDetails?.fclty_cell_num || "",
          clinicEmail: facilityDetails?.fclty_email || "",
          clinicImg: facilityDetails?.fclty_image || "",
          clinicAddress: facilityDetails?.fclty_address || "",
        });
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };
   const value = useMemo(() => ({ SubAdmin, setSubAdmin }), [SubAdmin]);

  return (
    <SubAdminContext.Provider value={value}>
      {children}
    </SubAdminContext.Provider>
  );
};

// Hook to use SubAdmin context
export const useSubAdmin = () => useContext(SubAdminContext);

