import React, { useEffect, useState } from "react";
import { useNavigate,Outlet  } from "react-router-dom";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const access_token = sessionStorage.getItem("subadminToken");
    if (!access_token) {
      navigate("/"); // Redirect to login if not authenticated
    } else {
      setIsAuthenticated(true); // Set authenticated status to true
    }
  }, [navigate]);

  // Render nothing until authentication is checked
  if (!isAuthenticated) {
    return null;
  }

  return <Outlet />;
};

export default PrivateRoute;
