
import React from "react";
import { StrictMode } from "react";
import App from "./App";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { SearchPatientDataProvider } from "./subadmin/context/SearchPatientData";
import { SearchDoctorDataProvider } from "./subadmin/context/SearchDoctorData";
import { SubAdminProvider } from "./subadmin/context/SubAdminContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
  <SubAdminProvider>
    <SearchPatientDataProvider>
      <SearchDoctorDataProvider>
        <App />
      </SearchDoctorDataProvider>
    </SearchPatientDataProvider>
  </SubAdminProvider>
</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
