import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import Loader from "./subadmin/components/loader/Loader";
import ScrollToTop from "./subadmin/components/ScrollToTop";
import "./App.css";
import PrivateRoute from "./auth/PrivateRoute";
import LoginWithOtp from "./auth/LoginWithOtp";
import LoginWithPassword from "./auth/LoginWithPassword";

//  facility admin
const DoctorManagementbysubadmin = lazy(() =>
  import("./subadmin/components/pages/AllDocotorList")
);
const AddNewPatientpolybysubadmin = lazy(() =>
  import("./subadmin/components/pages/AddNewPatient")
);

const DocRegisterbysubadmin = lazy(() =>
  import("./subadmin/components/pages/DoctorRegistrationForm")
);

const EditPatientByClinicbysubadmin = lazy(() =>
  import("./subadmin/components/pages/EditPatient")
);
const EditDoctorByClinicbysubadmin = lazy(() =>
  import("./subadmin/components/pages/EditDoctorProfilePoly")
);

const AllPatientsLIstbysubadmin = lazy(() =>
  import("./subadmin/components/pages/AllPatientsList")
);
const Dashboardbysubadmin = lazy(() =>
  import("./subadmin/components/layout/Dashboard")
);

const AllClinicStaffbysubadmin = lazy(() =>
  import("./subadmin/components/pages/ClincStaff")
);
const AddClinicStaffbysubadmin = lazy(() =>
  import("./subadmin/components/pages/StaffForm")
);
const EditStaffbysubadmin = lazy(() =>
  import("./subadmin/components/pages/EditStaffPage")
);
const SubadminProfile = lazy(() =>
  import("./subadmin/components/pages/SubadminProfile")
);
const EditProfile  = lazy(()=> import("./subadmin/components/pages/EditProfileFacilityAdmin"));
const Dashboard = lazy(() => import("./subadmin/components/pages/AdminDashbaord"));
const Appointments = lazy(() => import("./subadmin/components/pages/Appointments"));
const EditAppoointment = lazy(() => import("./subadmin/components/pages/EditAppointment"));
const UploadRecord = lazy(() => import("./subadmin/components/pages/Records/UploadRecord"))
const ViewRecord = lazy(() => import("./subadmin/components/pages/Records/ViewRecord"));
const AddFamilyMember = lazy(() => import("./subadmin/components/pages/AddFamliy"));
const MedicineScheduler = lazy(() => import("./subadmin/components/pages/PillScheduler/MedicineReminderTab"));
const EditSchedule = lazy(() => import("./subadmin/components/pages/PillScheduler/EditSchedule"));
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<LoginWithOtp />} />
          <Route path="/login/password" element={<LoginWithPassword />} />
          {/*  path for sub admin  */}
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboardbysubadmin />}>
              <Route
                path="dashboard"
                element={<Dashboard />}
              />
              <Route
                path="doctormanagementsubadmin"
                element={<DoctorManagementbysubadmin />}
              />
              <Route
                path="addnewpatientsubadmin"
                element={<AddNewPatientpolybysubadmin />}
              />
              <Route
                path="docregistersubadmin"
                element={<DocRegisterbysubadmin />}
              />
              <Route
                path="editpatientbyclinicsubadmin"
                element={<EditPatientByClinicbysubadmin />}
              />
              <Route
                path="editdoctorbyclinicsubadmin"
                element={<EditDoctorByClinicbysubadmin />}
              />
              <Route
                path="allpatientssubadmin"
                element={<AllPatientsLIstbysubadmin />}
              />
              <Route
                path="allclinicstaffsubadmin"
                element={<AllClinicStaffbysubadmin />}
              />
              <Route
                path="addclinicstaffsubadmin"
                element={<AddClinicStaffbysubadmin />}
              />
              <Route path="editstaffsubadmin" element={<EditStaffbysubadmin />} />
              <Route path="subadminprofile" element={<SubadminProfile />} />
              <Route path="editfacility" element={<EditProfile />} />
              <Route path="appointments" element={<Appointments />} />
              <Route path="editappointment" element={<EditAppoointment />} />
              <Route path="uploadrecord" element={<UploadRecord />} />
              <Route path="viewrecord" element={<ViewRecord />} />
              <Route path="addfamily" element={<AddFamilyMember />} />
              <Route path="addfamily" element={<AddFamilyMember />} />
              <Route path="medicinescheduler" element={<MedicineScheduler />} />
              <Route path="edit-reminder" element={<EditSchedule />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
