import React, { useState } from "react";
import logo from "../assets/logo/logo.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer} from "react-toastify";
import {
  TextField,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSubAdmin } from "../subadmin/context/SubAdminContext";
import apiService from "../api/apiService";
import Swal from "sweetalert2";
const LoginWithPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setSubAdmin } = useSubAdmin();
  const [showPassword, setShowPassword]= useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  // Handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Login as sub-admin
      const res = await apiService.userLogin({
        email: data.email,
        password: data.password,
        userType: "Clinic Admin",
        loginType: "Password"
      })


      if (res.status === 200) {
        const userDetails = res.data.userData;
        setSubAdmin({
          id: userDetails.id,
          mobilenumber: userDetails.mobilenumber,
          email: userDetails.email,
          flcty_id: userDetails.fclty_id,
          type: userDetails.type,
          password: userDetails.password,
          //  clinic details 
          clinicName: userDetails.facility_details.fclty_nm,
          clinicType: userDetails.facility_details.fclty_type,
          clinicPhone: userDetails.facility_details.fclty_cell_num,
          clinicEmail: userDetails.facility_details.fclty_email,
          clinicAddress: userDetails.facility_details.fclty_address,
          clinicImg: userDetails.facility_details.fclty_image,
        });
        sessionStorage.setItem("subadminToken", userDetails.token);
        navigate("/dashboard");
      }
      if (res.responseCode == 400) {
        Swal.fire({
          icon: "warning",
          text: res?.data.message || "Email not found ",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.response?.message || "Something went wrong",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };
    
  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <div className="container1"  style={{ backgroundColor: "#db9194" }}>
      <div className="d-flex justify-content-center align-items-center rounded login-box "  style={{ minHeight: "100vh" }}>
        <div className="p-4 ">
          <div className="row login-box shadow rounded p-4 bg-white" style={{ minWidth: "50%" }}>
            <div className="col-md-6 align-content-center text-center" id="background">
              <img
                src={logo}
                className=" "
                alt="web logo"
                height={100}
                width={200}
              />
            </div>
            <div className="col-md-6  bg-body-tertiary">
              <p className="text-center fs-4 fw-medium">Login to your Account</p>
              <form onSubmit={handleSubmit(onSubmit)} className="pt-2">
                {/* Mobile Number or Email */}
                <TextField
                  size="small"
                  fullWidth
                  label="Email Address"
                  type="email"
                  {...register("email", {
                    required: "Email is required",

                    minLength: { value: 8, message: "Password must be at least 8 characters" }
                  })}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}

                {/* Password Field */}
                <FormControl fullWidth sx={{ mb: 1 , mt:2}} variant="outlined">
                  <InputLabel size="small" htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                    })}
                    endAdornment={
                      <InputAdornment size="small" position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <FontAwesomeIcon className="fs-5" icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon className="fs-5" icon={faEye} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {errors.password && (
                    <small className="text-danger">{errors.password.message}</small>
                  )}
                </FormControl>

                {/* Submit Button */}
                <Button type="submit" variant="contained" fullWidth disabled={loading}>
                  Sign In
                </Button>
              </form>

              {/* Loading Spinner */}
              {loading && (
                <div className="d-flex justify-content-center pt-2">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}

              {/* Alternative Sign-in Option */}
              <div className="pt-3">
                <p className="text-center">_______________ or ______________</p>
                <Button fullWidth type="button" variant="outlined" onClick={() => navigate("/")}>
                  Login in with OTP
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Toast Notifications */}

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default LoginWithPassword;
